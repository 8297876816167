import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  users: null,
  user: null,
  roles: null,
  schools: null,
  grades: null,
  subjects: null,
  key: null,
  loading: false,
  status: "",
  backgroundImage:null
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.BULK_UPDATE_USERS:
      return state.set("loading", true).set("status", "");

    case actions.BULK_UPDATE_USERS_SUCCESS:
      return state.set("status", action.status).set("loading", false);

    case actions.SET_LOADING:
      return state.set("loading", action.loading);

    case actions.FETCH_USERS_SUCCESS:
      return state.set("users", action.users);

    case actions.FETCH_USER_DETAILS_SUCCESS:
      return state.set("user", action.user);

    case actions.REMOVE_BACKGROUND:
      return state.set("backgroundImage",action.backgroundImage);
      
    case actions.FETCH_CONFIGS_SUCCESS:
      return state
        .set("roles", action.roles)
        .set("schools", action.schools)
        .set("subjects", action.subjects)
        .set("grades", action.grades);

    case actions.CHANGE_STATUS_SUCCESS:
      const users = Object.assign([], state.get("users"));
      const userInd = users.findIndex((user) => user._id == action.userId);
      if (userInd > -1) {
        users[userInd].isActive = action.isActive;
      }
      return state.set("users", users);

    case actions.SET_FILTER:
      return state.set("key", action.key);

    case actions.RESET:
      return state
        .set("users", null)
        .set("user", null)
        .set("key", null);

    default:
      return state;
  }
}
